
import { Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom'

type Props = {}

const ErrorPage = (props: Props) => {
    const error: any = useRouteError()
    console.error(error, props);
    return (
        <div id="error-page" className='h-screen flex flex-col items-center justify-center'>
            <Typography variant='h3' align="center">Ooops!</Typography>
            <Typography>An unexpected error occured.</Typography>
            <Typography>{error?.statusText || error?.message}</Typography>
        </div>
    )
}

export default ErrorPage