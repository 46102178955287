

export const formatMarkdown = (markdownText: any) => {
    return markdownText.replace(/\*\*(.*?)\*\*/g, (match:any, p1:any) => p1.toUpperCase());
}


/*
 Books:[
    {
        "Title": "Deep Learning for NLP and Speech Recognition",
        "Authors": "Uday Kamath, John Liu, James Whitaker",
        "Description": "This comprehensive guide covers a variety of deep learning models including MLPs and transformers. It offers a practical approach with examples and applications."
    },
    {
        "Title": "Transformers for Natural Language Processing",
        "Authors": "Denis Rothman",
        "Description": "This book focuses on how transformers work, specifically in NLP applications. It provides a deep dive into the inner workings and implementation of models like GPT."
    },
    {
        "Title": "Neural Networks and Deep Learning: A Textbook",
        "Authors": "Charu C. Aggarwal",
        "Description": "This textbook provides coverage of deep learning and neural network architectures, including multi-layer perceptrons (MLPs). It connects theoretical concepts with practical applications."
    },
    {
        "Title": "Hands-On Machine Learning with Scikit-Learn, Keras, and TensorFlow, 2nd Edition",
        "Authors": "Aurélien Géron",
        "Description": "Although not specifically focused on GPT and MLPs, this book offers a practical guide to implementing a wide range of machine learning algorithms including foundational neural network concepts."
    }
]
*/ 



// "{\n  \"Books\": [\n    {\n      \"Title\": \"Deep Learning for NLP and Speech Recognition\",\n      \"Authors\": \"Uday Kamath, John Liu, James Whitaker\",\n      \"Description\": \"This comprehensive guide covers a variety of deep learning models including MLPs and transformers. It offers a practical approach with examples and applications.\"\n    },\n    {\n      \"Title\": \"Transformers for Natural Language Processing\",\n      \"Authors\": \"Denis Rothman\",\n      \"Description\": \"This book focuses on how transformers work, specifically in NLP applications. It provides a deep dive into the inner workings and implementation of models like GPT.\"\n    },\n    {\n      \"Title\": \"Neural Networks and Deep Learning: A Textbook\",\n      \"Authors\": \"Charu C. Aggarwal\",\n      \"Description\": \"This textbook provides coverage of deep learning and neural network architectures, including multi-layer perceptrons (MLPs). It connects theoretical concepts with practical applications.\"\n    },\n    {\n      \"Title\": \"Hands-On Machine Learning with Scikit-Learn, Keras, and TensorFlow, 2nd Edition\",\n      \"Authors\": \"Aurélien Géron\",\n      \"Description\": \"Although not specifically focused on GPT and MLPs, this book offers a practical guide to implementing a wide range of machine learning algorithms including foundational neural network concepts.\"\n    }\n  ]\n}"