import { Send } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";

const ChatInputForm = ({ onSubmit, content, setContent, disable }: {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    content: string,
    setContent: React.Dispatch<React.SetStateAction<string>>,
    disable?: boolean
}) => {
    const adjustHeight = (e: any) => {

        // e.target.style.height = 'inherit';
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }
    return (
        <Box
            component="form"
            onSubmit={onSubmit}
            noValidate
            autoComplete="off"
            className='flex flex-col mx-auto w-full max-w-lg space-y-3 pb-8 pt-3 mb-8 items-center relative'
        >
            <TextField
                sx={{
                    '& .MuiInputLabel-root': {
                        paddingTop: '0.5rem',
                    },
                    '& .MuiInputBase-root': {
                        overflow: 'hidden',
                        resize: 'none',  // Disables the resizing grip
                        paddingTop: '0.5rem',
                    },
                    '& textarea': {
                        resize: 'none',  // Ensures the textarea cannot be resized manually
                        height: 'auto',
                    },
                }}
                value={content}
                onChange={(e) => {
                    setContent(e.target.value);
                    adjustHeight(e);
                }}

                label="Ask me a question"
                multiline
                rows={5}
                maxRows={10}
                fullWidth
                disabled={disable}
            />
            <IconButton disabled={disable} type="submit" color="secondary" sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                transform: 'translate(10%, -80%)',

            }}>
                <Send fontSize="large" />
            </IconButton>


        </Box>


    )
}

export default ChatInputForm

