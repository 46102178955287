
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorPage from './error-page';
import Root from './routes/root';
import ChatPage from './routes/chat';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Stack justifyContent={'center'} alignItems={'center'} height={'100vh'} p={4}>
          <Stack rowGap={3} justifyContent={'center'} alignItems={'center'}>
            <Typography variant={'h4'} component={'h1'} className='text-sky-600'>Welcome to MemoryAI</Typography>
            <Typography variant={'body1'}>Click the button below to start chatting with our AI assistant powered by ChatGPT 4o</Typography>
            <Link to="/chat">
              <Button variant={'contained'} color={'primary'}>
                Start Chatting
              </Button>
            </Link>
          </Stack>
        </Stack>,
        errorElement: <ErrorPage />
      },
      {
        path: '/chat',
        element: <ChatPage />,
        errorElement: <ErrorPage />
      }
    ]
  },


])

function App() {

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} fallbackElement={<CircularProgress />} />
    </ThemeProvider>
  );
}

export default App;




