import { useState } from 'react'
import OpenAI from 'openai';
import ChatInputForm from '../components/forms/ChatInputForm';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Button, Container, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { ContentCopy, ForwardToInbox, IosShare, Refresh, SaveAlt, Share, Sms } from '@mui/icons-material';
import { formatMarkdown } from '../lib/helpers';
import { isEmpty } from 'lodash';
import axios from 'axios';


const openai = new OpenAI(
    {
        organization: 'org-4PmHIoSnjFAlvYGOWZ7SKhaH',
        project: 'proj_AdJ3z1W4GscEu1nNFoOeox5n',
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true

    }
);

const ChatPage = () => {
    const [content, setContent] = useState('')
    const [result, setResult] = useState(null)
    const [loading, setLoading] = useState(false)
    const formattedResult = result ? formatMarkdown(result) : ''

    // share menu popup state 
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClickShareMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseShareMenu = () => {
        setAnchorEl(null);
    };


    const chatBot = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        setResult('')
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/gpt/`, { prompt: content })
        const data = response.data
        // const completion = await openai.chat.completions.create({
        //     messages: [
        //         { role: 'system', content: 'You are a helpful assistant designed to answer questions in detail on a range of topics.' },
        //         { role: 'user', content: content },
        //     ],
        //     model: 'gpt-4o',
        //     // response_format: { type: 'json_object' }
        // })
        setLoading(false)
        // setResult(completion?.choices[0]?.message?.content)
        setResult(data)
    }

    // Email sharing function
    const shareViaEmail = () => {
        const subject = encodeURIComponent("ChatBot Result")
        const body = encodeURIComponent(formattedResult)
        // window.open(`mailto:?subject=${subject}&body=${body}`)
        window.location.href = `mailto:?subject=${subject}&body=${body}`
    }
    // SMS sharing function
    const shareViaSMS = () => {
        const body = encodeURIComponent(formattedResult)
        window.location.href = `sms:?body=${body}`
        // window.open(`sms:?body=${body}`)
    }

    // Generic sharing function
    const handleShareGeneric = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'ChatBot Result',
                    text: formattedResult,
                    url: document.location.href
                })

            } catch (error) {
                console.error('Error sharing:', error)

            }
        } else {
            alert('Web Share API not supported in your browser')
        }
    }



    return (

        <Container className='h-full flex flex-col items-center justify-center my-10 py-20'>
            <Typography variant='h5' component={'h1'} className='text-4xl font-bold' align='center' color={'textSecondary'}>GPT Assistant</Typography>
            <ChatInputForm disable={loading} onSubmit={chatBot} content={content} setContent={setContent} />
            {loading && <LinearProgress className='w-full' />}
            <br />

            {
                !isEmpty(result) && (
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <h2 className='text-2xl font-bold'>Result</h2>
                        <Stack direction={'row'} columnGap={2} mb={2} >
                            <Button
                                color="secondary"
                                disabled={isEmpty(result)}
                                startIcon={<ContentCopy />}

                                onClick={() => {
                                    navigator.clipboard.writeText(formattedResult)

                                }} >
                                Copy
                            </Button>
                            <Button color="secondary" disabled={isEmpty(result)} onClick={handleClickShareMenu} startIcon={<Share />}>
                                Share
                            </Button>
                            <Button color="secondary" disabled onClick={handleClickShareMenu} startIcon={<SaveAlt />}>
                                Save
                            </Button>
                        </Stack>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            padding: '2rem',
                            borderRadius: '1rem',
                            backgroundColor: 'background.default',
                            color: '#fff',
                            overflowY: 'auto',
                            // height: '28rem',
                            maxHeight: '70vh',
                            width: '100%',
                            wordWrap: 'break-word',
                            pb: 2,
                            mb: 2
                        }}>
                            <Markdown remarkPlugins={[remarkGfm]}>{result}</Markdown>
                        </Box>

                        <Button disabled={isEmpty(result)} color="secondary" onClick={() => setResult('')} startIcon={<Refresh />}>
                            Clear result
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseShareMenu}
                        >
                            <MenuList >
                                <MenuItem onClick={() => {
                                    shareViaEmail()
                                    handleCloseShareMenu()
                                }}>
                                    <ListItemIcon>
                                        <ForwardToInbox />
                                    </ListItemIcon>
                                    <ListItemText>Email</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    shareViaSMS()
                                    handleCloseShareMenu()
                                }}>
                                    <ListItemIcon>
                                        <Sms />
                                    </ListItemIcon>
                                    <ListItemText>SMS</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleShareGeneric()
                                    handleCloseShareMenu()

                                }}>
                                    <ListItemIcon>
                                        <IosShare />
                                    </ListItemIcon>
                                    <ListItemText>More Options</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Menu>

                    </Box>
                )
            }
        </Container >

    )
}

export default ChatPage