import { Stack, Typography } from '@mui/material'
import React from 'react'
import { DateTime } from 'luxon'

type Props = {}

function Footer({ }: Props) {
    return (
        <Stack alignItems={'center'} p={4}>
            {/* add grids later */}
            <div><Typography variant='caption' color={'textSecondary'}><span>&copy; {DateTime.now().year}</span> All Rights Reserved MemoryAI by ToniaJ</Typography></div>
        </Stack>
    )
}

export default Footer