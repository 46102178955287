import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#0284c7',
        },
        secondary: {
            main: '#a16207',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#f43f5e',
        },
        success: {
            main: '#4caf50',
        },
    },
});

export default theme;
