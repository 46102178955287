import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../components/layout/Nav'
import { Box, Paper } from '@mui/material'
import Footer from '../components/layout/Footer'

type Props = {
    children?: React.ReactNode
}

const Root = ({
    children
}: Props) => {
    return (
        <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '100%',
            pb: 2,
        }}>
            <Nav />
            <Box id="detail">
                <Outlet />
            </Box>
            <Footer />

        </Paper>
    )
}

export default Root